import * as React from 'react';
import Title from "../Common/Title";
import {StaticImage} from "gatsby-plugin-image";
import {getImageAltText} from "../../utils/common";

const Certificates = () => (
  <div className="panel mb-5 certificates">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="mb-2">
            <Title>Нові методики та новітні технології </Title>
          </div>
          <p className="mb-6 text-muted">&nbsp;&nbsp;
            За роки існування медичної установи впроваджено <span>нові методики та новітні
            технології у сфері лазерної медицини</span> за основними напрямками: <br/>хірургія (варикозна
            хвороба нижніх кінцівок, геморої, контрактура Дюпюїтрена, кістозні новоутворення
            шкіри та підшкірної клітковини), дерматологія (новоутворення шкіри),
            гінекологія (гістероскопія, гістерорезекція, інтимна контурна пластика та технологія
            фракційного фототермолізу). <br/>Також одним із напрямків роботи медичної установи є
            <span>пластична хірургія.</span>
          </p>
        </div>
      </div>
      <div className="row form-row align-items-center">
        <div className="col-3">
          <StaticImage src="../../assets/images/certificates/cert9.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
        </div>
        <div className="col-6">
          <div className="row form-row align-items-end mb-2">
            <div className="col-7">
              <StaticImage src="../../assets/images/certificates/cert16.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
            <div className="col-5">
              <StaticImage src="../../assets/images/certificates/cert12.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
          </div>
          <div className="row form-row">
            <div className="col-7">
              <StaticImage src="../../assets/images/certificates/cert1.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
            <div className="col-5">
              <StaticImage src="../../assets/images/certificates/cert8.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
          </div>
          <div className="row form-row">
            <div className="col-5">
              <StaticImage src="../../assets/images/certificates/cert5.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
            <div className="col-7">
              <StaticImage src="../../assets/images/certificates/cert7.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
          </div>
        </div>
        <div className="col-3">
          <StaticImage src="../../assets/images/certificates/cert6.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
        </div>
      </div>

      <div className="row form-row align-items-center">
        <div className="col-3">
          <StaticImage src="../../assets/images/certificates/cert2.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
        </div>
        <div className="col-6">
          <div className="row form-row align-items-end mb-2">
            <div className="col-6">
              <StaticImage src="../../assets/images/certificates/cert14.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
            <div className="col-6">
              <StaticImage src="../../assets/images/certificates/cert17.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
          </div>
          <div className="row form-row">
            <div className="col-8">
              <StaticImage src="../../assets/images/certificates/cert13.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
            </div>
          </div>
        </div>
        <div className="col-3">
          <StaticImage src="../../assets/images/certificates/cert15.jpg" className="img-fluid" placeholder="blurred" alt={getImageAltText()}/>
        </div>
      </div>
    </div>
  </div>
);

export default Certificates;